<template>

      <div class="container">
        <div style="min-height: 400px" class="row">

         <div class="col-md-2 col-lg-2 col-xs-12">
             <sidebar/>
         </div>

          <div class="col-md-10 col-sm-10 col-xs-12">
            <div class="table_overflow">
            <h4 class="heading"></h4>
            <br />
            <table class="table text-center table-hover table-striped table-bordered">
              <thead>
                <tr>
                  <td>#</td>
                  <td>Invoice</td>
                  <td>Amount</td>
                  <td>Paid</td>
                  <td>Status</td>
                  <td>Date</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody class="table-responsive">
                <h1 v-if="loading">
                  <i class="fa fa-spin fa-spinner"></i>
                </h1>
                <tr
                  v-else
                  v-for="(order, index) in order_lists.data"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ order.invoice_no }}</td>
                  <td class="text-left">
                    {{parseInt(order.total) - (parseInt(order.discount)) +parseInt(order.shipping_cost)}}
                  </td>
                  <td>
                     {{
                      parseInt(order.paid)

                    }}
                  </td>
                  <td>
                    <span class="badge badge-primary" v-if="order.status == 1">New</span>
                    <span class="badge badge-warning" v-if="order.status == 2">Pending</span>

                    <span class="badge badge-success" v-if="order.status == 3"
                      >Approved</span
                    >
                    <span class="badge badge-success" v-if="order.status == 4"
                      >Shipment</span
                    >
                    <span class="badge badge-success" v-if="order.status == 5"
                      >Delivered</span
                    >
                    <span class="badge badge-danger" v-if="order.status == 6"
                      >Cancel</span
                    >
                    <span class="badge badge-danger" v-if="order.status == 7"
                      >Return</span
                    >
                  </td>
                  <td>{{ order.created_at }}</td>

                  <td>
                    <router-link
                      :to="{ name:'order_details',params: { id: order.id } }"
                      class="btn btn-xs  btn-dark order_details"
                    >
                     <i class="bi bi-eye-fill"></i>
                    </router-link>
                    <div class="spinner-grow text-success pay_now_spinner" role="status">
                      <span class="sr-only"></span>
                    </div>
                    <a v-if="parseInt(order.paid) < (parseInt(order.total) - (parseInt(order.discount)) +parseInt(order.shipping_cost))"  @click.prevent="paynow(order.id)" class="btn btn-xs btn-primary pay_now_btn">Pay Now</a>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="row">
              <div class="col-lg-6">
                <pagination
                  :data="order_lists"
                  @pagination-change-page="getOrderList"
                ></pagination>
              </div>
              <div
                class="col-lg-6 mt-1"
                style="margin-top: 25px; text-align: right"
              >
                <p>
                  Showing
                  <strong>{{ order_lists.from }}</strong> to
                  <strong>{{ order_lists.to }}</strong> of total
                  <strong>{{ order_lists.total }}</strong> entries
                </p>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>


</template>

<script>
import sidebar from "../../components/Sidebar.vue";
const isReloaded = localStorage.getItem('mDashboardReloaded');
export default {

  created() {
    this.$store.dispatch('user')
  },

  data() {
    return {
      order_lists: {},
      loading: true,
      isReloaded : isReloaded ? true : false
    };
  },
  components: {
    sidebar,
  },
  methods: {

    isReloadedMethod(){
      setTimeout(function(){
        if(this.isReloaded == false){
          localStorage.setItem('mDashboardReloaded',true)
          this.isReloaded = true;
          location.reload();
        }
      },
      1500)
    },
    getOrderList(page = 1) {
      this.axios.get("customer/order/list?page=" + page, {
             params:{
                 user_token:localStorage.getItem('user_token')
                }
             })
      .then((resp) => {
      //  console.log(resp);
        if (resp.data.status == "SUCCESS") {
          this.order_lists = resp.data.orders;
          this.loading = false;
        }
      });
    },

    orderDate(created_at) {
      var d = new Date(created_at);
      console.log(d);
      var month = d.getMonth();
      var day = d.getDate();
      var year = d.getFullYear();
      let f_date = `${day.toString().length == 2 ? day : "0" + day}-${
        month.toString().length == 2 ? month : "0" + month
      }-${year}`;

      return f_date;
    },
    paynow(id){
      document.getElementById('__o_id').value=id;
      document.getElementById('payment_form').submit();
    },



  },
  computed: {
    
    user() {
      return this.$store.getters.user;
    },
  },
  mounted(){
    
    this.isReloadedMethod();
    this.getOrderList();

  }

};
</script>


<style>
.user_profile_icon {
  width: 80px;
  height: 80px;
}

.order_details {
  border-radius: 5px;
  height: 32px;
  margin-right: 10px;
}

.table_overflow {
  overflow-x: auto;
}

.pay_now_spinner {
    position: absolute;
    width: 1.2rem !important;
    height: 1.2rem !important;
    margin: 5px 3px;
}

.pay_now_btn {
    height: 32px;
    font-size: 12px;
    width: 95px;
}

@media screen and (max-width:650px) {
  .user_profile_icon {
    width: 50px;
    height: 50px;
  }

  .order_details {
    border-radius: 5px;
    height: 32px;
    margin-right: 0px;
    width: 73px;
}

  .pay_now_btn {
      height: 32px;
      font-size: 12px;
      width: 73px;
      margin-top: 5px;
  }

  .pay_now_spinner {
      display: none;
  }



}
</style>